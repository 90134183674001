import { FunctionComponent, useEffect } from 'react';
import PdfViewer from 'components/shared/PdfViewer';

const TermsOfUsePageUK: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return <PdfViewer url="/docs/COMMUNITY_X_SEEN_Terms_of_Use_US.pdf" />;
};

export default TermsOfUsePageUK;
