import { FunctionComponent, useEffect } from 'react';
import PdfViewer from 'components/shared/PdfViewer';

const PrivacyNoticeUK: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return <PdfViewer url="/docs/PRIVACY.pdf" />;
};

export default PrivacyNoticeUK;
