import { FunctionComponent, useEffect } from 'react';
import MainMenu2 from 'components/shared/MainMenu2';
import Footer2 from 'components/shared/Footer2';
import LegalPageDialog from 'components/sections/LegalPageDialog';

const PrivacyNotice2: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return (
    <>
      <MainMenu2 />
      <LegalPageDialog
        title="Privacy Notice"
        description="Please select your country of residence to view the relevant Community X SEEN Privacy Notice"
        links={[
          {
            url: '/legal/privacy-notice-us',
            children: 'USA',
          },
          {
            url: '/legal/privacy-notice-uk-ireland-canada',
            children: 'UK, Ireland & Canada',
          },
        ]}
      />
      <Footer2 />
    </>
  );
};

export default PrivacyNotice2;
