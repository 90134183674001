import TagManager from 'react-gtm-module'

/**
 * Initialize Google Tag Manager
 * Usually called after obtaining GDRP consent
 */
export default function initGtm(): void {

  // only run in production
  const env = process.env.NODE_ENV;
  if (env !== 'production') return;

  // only run if cookies accepted
  const gdpr = localStorage.getItem('GDPR:accepted');
  if (gdpr !== 'true') return;

  // trigger
  TagManager.initialize({
    gtmId: 'GTM-P95SGPR',
  })
}

