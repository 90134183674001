import { FunctionComponent, useEffect } from 'react';
import PdfViewer from 'components/shared/PdfViewer';

const PrivacyNoticeUSA: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return <PdfViewer url="/docs/PRIVACY_US.pdf" />;
};

export default PrivacyNoticeUSA;
