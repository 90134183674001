import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFooter, IMainMenu } from 'types/generated/strapi';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from 'utils/urls';

export const fetchMainMenu = createAsyncThunk('fetchMainMenu', async () => {
  const response = await strapi.get<IMainMenu>(getStrapiURL('/main-menu'));
  if (response.ok) {
    return response.payload;
  }
  console.error(response.error);
  return {} as IMainMenu;
});

export const fetchFooter = createAsyncThunk('fetchFooter', async () => {
  const response = await strapi.get<IFooter>(getStrapiURL('/footer'));
  if (response.ok) {
    return response.payload;
  }
  console.error(response.error);
  return {} as IFooter;
});

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    mainMenu: undefined as IMainMenu | undefined,
    footer: undefined as IFooter | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMainMenu.fulfilled, (state, action) => {
      state.mainMenu = action.payload;
    });
    builder.addCase(fetchFooter.fulfilled, (state, action) => {
      state.footer = action.payload;
    });
  },
});
