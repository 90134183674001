import { FunctionComponent, useEffect } from 'react';
import MainMenu2 from 'components/shared/MainMenu2';
import Footer2 from 'components/shared/Footer2';
import LegalPageDialog from 'components/sections/LegalPageDialog';

const TermsOfUsePage2: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return (
    <>
      <MainMenu2 />
      <LegalPageDialog
        title="Terms of Use"
        description="Please select your country of residence to view the relevant Community X SEEN Terms of Use"
        links={[
          {
            url: '/legal/terms-of-use-us',
            children: 'USA',
          },
          {
            url: '/legal/terms-of-use-uk-ireland-canada',
            children: 'UK, Ireland & Canada',
          },
        ]}
      />
      <Footer2 />
    </>
  );
};

export default TermsOfUsePage2;
