import { FunctionComponent } from 'react';
import styles from './Error404.module.css';

const Error404: FunctionComponent = () => {
  return (
    <>
      <main className={styles.errorPage}>
        <h1>404 Not Found</h1>
      </main>
    </>
  );
};

export default Error404;
