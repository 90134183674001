import { Helmet } from 'react-helmet';
import { FunctionComponent } from 'react';
import { ISEO } from 'types/generated/strapi';

export interface ISeoHeadProps {
  seo?: ISEO;
}

const SeoHead: FunctionComponent<ISeoHeadProps> = (props) => {
  const { seo } = props;

  const defaults = {
    title: 'Community X SEEN',
    description: '',
  };

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{seo?.metaTitle || defaults.title}</title>
      <meta
        name="description"
        content={seo?.metaDescription || defaults.description}
      />
      {/* <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content={props.type} />
      <meta property="og:title" content={props.seo?.metaTitle || "Default title"} />
      <meta property="og:description" content={props.seo?.metaDescription || "Default description"} />
      <meta property="og:image" content={ogImg} /> */}
    </Helmet>
  );
};

export default SeoHead;
