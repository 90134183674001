import { FunctionComponent, useEffect } from 'react';
import styles from './index.module.css';

const AntiSlaveryStatement: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return (
    <>
      <iframe
        title="AntiSlaveryStatement"
        src="https://www.exposure.net/london/anti-slavery-statement/"
        className={styles.iframe}
      />
    </>
  );
};

export default AntiSlaveryStatement;
