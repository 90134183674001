/**
 * -----------------------------------------------------------------------------
 *  Get Strapi media URL
 *  Strapi in production uses s3. Strapi local uses uploads.
 * -----------------------------------------------------------------------------
 */

export function getStrapiMediaUrl(path: string): string {
  if (process.env.NODE_ENV === 'production') return path;

  const cmsUrl = getStrapiURL('');
  return cmsUrl.includes('localhost') ? cmsUrl + path : path;
}

/**
 * -----------------------------------------------------------------------------
 *  Get Strapi URL from environment
 * -----------------------------------------------------------------------------
 */

export function getStrapiURL(path: string): string {
  return `${
    process.env.REACT_APP_CMS_URL || 'https://cms.communityxseen.com'
  }${path}`;
}