import { FunctionComponent, useEffect } from 'react';
import PdfViewer from 'components/shared/PdfViewer';

const CookiePolicy: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return <PdfViewer url="/docs/cookie-policy.pdf" />;
};

export default CookiePolicy;
