import styles from './index.module.css';
import Logo from 'assets/svg/new/logo.svg';
import clsx from 'clsx';

type LegalPageDialogProps = {
  title: string;
  description: string;
  links: LinkProps[];
};

type LinkProps = {
  url: string;
  children: React.ReactNode;
};

const LegalPageDialog = ({
  title,
  description,
  links,
}: LegalPageDialogProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <img className={styles.logo} src={Logo} alt="CXS" />
        <div className={styles.dialog}>
          <div className={styles.content}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.description}>{description}</p>
          </div>
          <div className={styles.buttons}>
            {links.slice(0, 2).map((link, index) => (
              <a
                key={link.url + index}
                className={clsx(
                  styles.button,
                  index === 1 && styles.buttonSecondary
                )}
                href={link.url}
                target="_blank"
                rel="noreferrer"
              >
                {link.children}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalPageDialog;
