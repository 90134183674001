export default function PdfViewer(props: { url: string }) {
  return (
    <iframe
      title="PDF Viewer"
      src={`https://www.exposure.net/pdf-viewer/index.html?url=${
        window.location.origin + props.url
      }`}
      style={{ width: '100%', height: '100vh' }}
    />
  );
}
