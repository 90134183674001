import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { layoutSlice } from './layoutSlice';

const store = configureStore({
  reducer: combineReducers({
    layout: layoutSlice.reducer,
  }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
