import { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.css';
import { ReactComponent as CloseIcon } from 'assets/svg/new/close.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/new/menu.svg';
import clsx from 'clsx';
// import { NavLink, Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import Logo from 'assets/svg/new/logo.svg';
import Email from 'assets/img/new/Email.png';
import Tiktok from 'assets/img/new/Tiktok.png';
import Instagram from 'assets/img/new/Instagram.png';

interface MainMenuProps {}

const MainMenu2: FunctionComponent<MainMenuProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const mainMenu = useAppSelector((state) => state.layout.mainMenu);

  // const { links = [], socialLinks = [] } = { ...mainMenu };
  const { socialLinks = [] } = { ...mainMenu };
  const showMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(true);
  };

  const hideMenu = () => setMenuOpen(false);

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener('click', hideMenu);
    };

    const removeEventListeners = () => {
      document.removeEventListener('click', hideMenu);
    };

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  // const isRelativeLink = (url: string) => url.startsWith('/');

  return (
    <div className={clsx(styles.mainMenu, menuOpen && styles.mainMenuFixed)}>
      <div className={styles.mainMenuInner}>
        {/* Logo and menu buttons */}
        <div className={styles.logoContainer}>
          {/* Logo */}
          <Link className={styles.logo} onClick={hideMenu} to="/">
            <img className={styles.logoImg} src={Logo} alt="CXS" />
          </Link>

          {/* Menu Icon */}
          <button
            className={clsx(
              styles.menuButton,
              menuOpen && styles.menuButtonHide
            )}
            onClick={showMenu}
          >
            <span className="visually-hidden">Show menu</span>
            <MenuIcon className={styles.menuIcon} />
          </button>

          {/* Close Icon */}
          <button
            className={clsx(
              styles.closeButton,
              !menuOpen && styles.closeButtonHide
            )}
            onClick={hideMenu}
          >
            <span className="visually-hidden">Hide menu</span>
            <CloseIcon className={styles.closeIcon} />
          </button>
        </div>

        {/* Links container */}
        <div
          className={clsx(
            styles.navLinksWrapper,
            menuOpen && styles.navLinksWrapperShow
          )}
          onClick={(event) => event.stopPropagation()}
        >
          <div>
            <ul className={styles.navItems}>
              <li>
                <a
                  className={clsx(
                    styles.navbarLinkItemLink,
                    styles.navbarLinkItemLinkSignUp
                  )}
                  href={'https://member.communityxseen.com/sign-up'}
                >Sign Up</a>
              </li>
              <li>
                <a
                  className={clsx(
                    styles.navbarLinkItemLink
                  )}
                  href={'https://member.communityxseen.com/login'}
                >Login</a>
              </li>
              {/* {links
                .filter(
                  (link) => link.label === 'Sign Up' || link.label === 'Login'
                )
                .map((link) => (
                  <li className={styles.navbarLinkItem} key={link.id}>
                    {isRelativeLink(link.url) && (
                      <NavLink
                        className={({ isActive }) =>
                          clsx(
                            styles.navbarLinkItemLink,
                            link.label === 'Sign Up' &&
                              styles.navbarLinkItemLinkSignUp
                          )
                        }
                        onClick={hideMenu}
                        to={link.url}
                      >
                        {link.label}
                      </NavLink>
                    )}
                    {!isRelativeLink(link.url) && (
                      <a
                        className={clsx(
                          styles.navbarLinkItemLink,
                          link.label === 'Sign Up' &&
                            styles.navbarLinkItemLinkSignUp
                        )}
                        href={link.url}
                        rel="noreferrer"
                      >
                        {link.label}
                      </a>
                    )}
                  </li>
                ))} */}
            </ul>
          </div>
          <div className={styles.socialLinksWrapper}>
            <ul className={styles.socialLinks}>
              {/* Social links */}
              {socialLinks.map((socialLink, socialLinkIndex) => {
                const { url, icon } = socialLink;
                const image =
                  icon === 'Instagram'
                    ? Instagram
                    : icon === 'Tiktok'
                    ? Tiktok
                    : icon === 'Mail'
                    ? Email
                    : '';
                return (
                  <li key={`social-${socialLinkIndex}`}>
                    <a href={url} target="_blank" rel="noreferrer">
                      <img
                        src={image}
                        alt="social media"
                        className={styles.socialIcon}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu2;
