import PdfViewer from 'components/shared/PdfViewer';
import { FunctionComponent, useEffect } from 'react';

const SustainabilityPolicyPage: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return <PdfViewer url="/docs/sustainability-policy.pdf" />;
};

export default SustainabilityPolicyPage;
