import { FunctionComponent, useEffect } from 'react';
import PdfViewer from 'components/shared/PdfViewer';

const TermsOfUsePageUK: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return <PdfViewer url="/docs/US_CXS_Engagement_Letter_for_Clients_Final_MAY2024.pdf" />;
};

export default TermsOfUsePageUK;
